
import './App.scss';
import Header from './components/header';
import Footer from './components/footer';
import * as React from "react";
import { Routes, Route, Outlet, Link } from "react-router-dom";
import PairUp from './tools/pair-up/Pair-Up';
import ReflectionWorkshop from './tools/reflection-workshop/ReflectionWorkshop';
import ToolsPage from './pages/Tools';
import ImpressumPage from './pages/Impressum';
import AboutPage from './pages/About';
import DatenschutzPage from './pages/Datenschutz';
import RewardRandomizer from './tools/reward-randomizer/RewardRandomizer';
import GuidedMeditations from './tools/guided-meditations/GuidedMeditations';
import FermiAssistant from './tools/fermi-assistant/FermiAssistant';
import ImageEditorHub from './tools/image-editor/ImageEditorHub';

export default function App() {
  return (
    <div>

      {/* Routes nest inside one another. Nested route paths build upon
            parent route paths, and nested route elements render inside
            parent route elements. See the note about <Outlet> below. */}
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<ToolsPage />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="impressum" element={<ImpressumPage />} />
          <Route path="datenschutz" element={<DatenschutzPage />} />

          {/* Tools */}
          <Route path="pairup" element={<PairUp />} />
          <Route path="reflection-workshop" element={<ReflectionWorkshop />} />
          <Route path="reward-randomizer" element={<RewardRandomizer />} />
          <Route path="guided-meditations" element={<GuidedMeditations />} />
          <Route path="fermi-assistant" element={<FermiAssistant />} />
          <Route path="image-editor" element={<ImageEditorHub /> } />

          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>

    </div>
  );
}

function Layout() {
  return (
    <div>
      {/* A "layout route" is a good place to put markup you want to
          share across all the pages on your site, like navigation. */}
      <Header />

      {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}
      <div className="page-body">
        <div className="page-content">
          <Outlet />
        </div>
      </div>

      <Footer />
    </div>
  );
}



function NoMatch() {
  return (
    <div>
      <h2>Whoops, this link seems to broken</h2>
      <p>
        <Link to="/">Return to overview</Link>
      </p>
    </div>
  );
}