import './image-editor-hub.scss';

const links = [
    { name: 'New Script', link: '' },
    { name: 'Add Transparency', link: 'transparent-color' },
    { name: 'Add Outline', link: 'add-outline' },
];

const baseUrl = '/tools/image-editor/editor.html';

export default function ImageEditorHub() {

  return (
    <div className="tool-image-editor-hub">
        <h1>Image Editor</h1>
        <ul>
            {links.map(link => (
                <li key={link.name}>
                    <a href={link.link ? `${baseUrl}?load=${link.link}` : baseUrl}>{link.name}</a>
                </li>
            ))}
        </ul>
    </div>
  );
}
